.ui.page-footer {
  margin: 2rem;
  #nr-footer-container {
    border-top: 1px solid @lightGrey;
  }

  p {
    color: white;
  }
  a {
    color: white;
    text-decoration: none;
  }
  .footer-top {
    color: white;
  }
  .footer-bottom {
    color: white;
  }
}
