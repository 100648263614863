.ui.page-header {
    border: 0;

  .outer-navbar {
    background-color: @oxfordBlue;
    border: 0;
  }

  #invenio-menu {
    gap: unset;
  }
}

  .ui.page-header #invenio-nav.ui.menu.active #invenio-menu.ui.menu .item a:not(.ui.button) {
    color: black !important;
    padding: 0.5rem 0;
  }

@media all and (max-width: @largestTabletScreen) {
    #invenio-nav.ui.menu {
      &.active #invenio-menu.ui.menu {
        flex-direction: column;
        position: fixed;
        right: 0;
        top: 0;
        min-width: 21rem;
        max-width: 25rem;
        box-shadow: 0px 0px 7px rgba(0, 0, 0, 0.2);
        background-color: rgb(255, 255, 255);
        height: 100vh;
        z-index: 3;
        padding: 2rem 1.5rem 1.5rem 1.5rem;
        overflow-y: scroll;
        justify-content: flex-start;

        .item {
          width: 100%;
          padding: 0.5rem 0;

          a:not(.ui.button) {
            color: @textColor;
            padding: 0;
          }

          &.search-bar {
            width: 100%;

            .ui.input {
              width: 99% !important;
            }
          }

          .icon.inverted {
            color: @textColor;
          }

        &.left.menu {
            padding: 0;
            margin-left: unset !important;
            flex-direction: column;
            align-items: center;

            .sub-menu {
              width: 100%;
              margin: 2rem 0 0 0;

              .ui.heading {
                color: @textColor;
              }

              .item {
                padding: 0.5rem 1rem;
              }
            }

            form {
              margin-top: 1rem;
            }
          }

          &.right.menu {
            padding: 0;
            margin-left: unset !important;
            flex-direction: column;
            align-items: center;

            .sub-menu {
              width: 100%;
              margin: 2rem 0 0 0;

              .ui.heading {
                color: @textColor;
              }

              .item {
                padding: 0.5rem 1rem;
              }
            }

            form {
              margin-top: 1rem;
            }
          }
        }

        #invenio-close-burger-menu-icon {
          margin-left: auto;
          margin-bottom: 2rem;

          .navicon {
            background: transparent;

            &:before {
              transform: rotate(-45deg);
            }

            &:after {
              transform: rotate(45deg);
            }
          }
        }
      }

      &:not(.active) {
        .mobile-hidden {
          display: none;
        }
      }
    }
  }

 .ui.page-header #invenio-burger-menu-icon .navicon::before, .ui.page-header #invenio-burger-menu-icon .navicon::after {
  display: block;
  background: #fff;
    background-color: rgb(255, 255, 255);
    background-position-x: 0%;
    background-position-y: 0%;
    background-repeat: repeat;
    background-attachment: scroll;
    background-image: none;
    background-size: auto;
    background-origin: padding-box;
    background-clip: border-box;
  content: "";
  height: 100%;
  position: absolute;
  transition: all 0.2s ease-out;
  width: 100%;
}

.ui.page-header #invenio-burger-menu-icon .navicon {
  background: #fff;
    background-color: rgb(255, 255, 255);
    background-position-x: 0%;
    background-position-y: 0%;
    background-repeat: repeat;
    background-attachment: scroll;
    background-image: none;
    background-size: auto;
    background-origin: padding-box;
    background-clip: border-box;
  display: block;
  height: 2px;
  position: relative;
  transition: background 0.2s ease-out;
  width: 18px;
}