/*******************************
            Theme
*******************************/

/*
  The type is the type of the component as defined in semantic-ui - element, collection, view, module
  For simple component, 'element' is the correct type
*/
@type: "element";

/*
  This is the name of the component - though not required, keep it the same as the filename
*/
@element: "histogram_container";

/*******************************

    DO NOT FORGET !!!

Edit *theme.config* in your site 
and add (replace 'sample' with the
name of the component from above)

@sample : 'default';

*******************************/

@import (multiple) "../../theme.config";

/*
  This is the styling of your component. The class should start with .ui.<component-name>

  Note: use variables here, not the exact values. Placing the values to another file (sample.variables)
  will allow users to override these values.
 */
.ui.histogram-container {
  width: 100%;

  .histogram-svg-container {
    overflow: auto;

    /* histogram.css */

    .opacity-0 {
      opacity: 0;
    }

    .opacity-25 {
      opacity: 0.25;
    }

    .opacity-50 {
      opacity: 0.5;
    }

    .opacity-75 {
      opacity: 0.75;
    }

    .opacity-100 {
      opacity: 1;
    }

    position: relative;

    width: 100%;
    .histogram-rectangle {
      fill: @histogramRectangleColor;
    }
    .histogram-rectangle:hover {
      fill: @histogramRectangleHoverColor;
      cursor: pointer;
    }
    .histogram-rectangle-single {
      fill: @histogramRectangleColor;
    }

    .histogram-rectangle-overlay {
      fill: white;
    }
    .histogram-rectangle-overlay:hover {
      fill: @histogramRectangleOverlayColor;
      cursor: pointer;
    }
    .unselected-slider {
      height: @unselectedSliderHeight;
      fill: @unselectedSliderColor;
    }
    .selected-slider {
      height: @selectedSliderHeight;
      fill: @selectedSliderColor;
      fill-opacity: 0.6;
    }
    .slider-thumb-container {
      cursor: move;
    }
    .slider-thumb {
      outline: none;
      fill: @thumbColor;
      stroke: @thumbStroke;
      stroke-width: @thumbStrokeWidth;
      -webkit-user-select: none;
      -khtml-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;

      &:hover {
        fill: @thumbFillHovered;
      }
    }
    .slider-thumb-label {
      fill: @sliderThumbLabelFill;
      font-size: @sliderThumbFontSize;
      text-anchor: middle;
      -webkit-user-select: none;
      -khtml-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;
    }

    .slider-thumb:focus-visible {
      outline: 3px solid black;
      border-radius: 100px;
    }
    .y-axis-indicator {
      stroke: @yaxisLineStroke;
      stroke-dasharray: 5 5;
      stroke-opacity: 0.4;
    }
    .y-axis-indicator-text {
      font-size: @yaxisTextFontSize;
    }
    svg {
      min-width: 100%;
    }
  }
}

/*
  This will load the overrides, enabling the user of your component to override your css if required
 */
.loadUIOverrides();
