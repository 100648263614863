/*******************************
            Theme
*******************************/

/*
  The type is the type of the component as defined in semantic-ui - element, collection, view, module
  For simple component, 'element' is the correct type
*/
@type: "module";

/*
  This is the name of the component - though not required, keep it the same as the filename
*/
@element: "value_list";

@import (multiple) "../../theme.config";

/*
  This is the styling of your component. The class should start with .ui.<component-name>

  Note: use variables here, not the exact values. Placing the values to another file (sample.variables)
  will allow users to override these values.
 */
.ui.value-list {
  dl {
    display: flex;
    flex-flow: row wrap;

    & > dt {
      flex-basis: 20%;
      padding: 0px 4px;
      font-weight: 600;
      min-width: 20em;
    }

    & > dd {
      flex-basis: 65%;
      flex-grow: 1;
      margin-bottom: 1em;
      break-after: always;
    }

    @media only screen and (max-width: @largestMobileScreen) {
      & > dt {
        flex-basis: 100%;
      }

      & > dd {
        flex-basis: 100%;
      }
    }
  }
}

/*
  This will load the overrides, enabling the user of your component to override your css if required
 */
.loadUIOverrides();
