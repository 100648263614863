/*******************************
            Theme
*******************************/

/*
  The type is the type of the component as defined in semantic-ui - element, collection, view, module
  For simple component, 'element' is the correct type
*/
@type: "module";

/*
  This is the name of the component - though not required, keep it the same as the filename
*/
@element: "account_dropdown";

/*******************************

    DO NOT FORGET !!!

Edit *theme.config* in your site 
and add (replace 'sample' with the
name of the component from above)

@sample : 'default';

*******************************/

@import (multiple) "../../theme.config";

/*
  This is the styling of your component. The class should start with .ui.<component-name>

  Note: use variables here, not the exact values. Placing the values to another file (sample.variables)
  will allow users to override these values.
 */

.ui.account_dropdown {
  & .ui.labeled.button {
    background: @background;
    & > i.dropdown.icon {
      background: @background;
    }
  }
  #user-profile-dropdown-btn {
    padding-left: 1.5rem !important;
    padding-right: 1.5rem !important;
  }
}
.loadUIOverrides();
