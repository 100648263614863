/*******************************
            Theme
*******************************/

/*
  The type is the type of the component as defined in semantic-ui - element, collection, view, module
  For simple component, 'element' is the correct type
*/
@type: "view";

/*
  This is the name of the component - though not required, keep it the same as the filename
*/
@element: "page_footer";

/*******************************

    DO NOT FORGET !!!

Edit *theme.config* in your site 
and add (replace 'sample' with the
name of the component from above)

@sample : 'default';

*******************************/

@import (multiple) "../../theme.config";

/*
  This is the styling of your component. The class should start with .ui.<component-name>

  Note: use variables here, not the exact values. Placing the values to another file (sample.variables)
  will allow users to override these values.
 */

.ui.page-footer {
  margin: 0;
  min-height: @pageFooterMinHeight;
  #nr-footer-container {
    background-color: @pageFooterBackgroundColor;
    border-top: 1px solid black;
    padding: @pageFooterPadding;

    & .ui.container {
      padding: 1rem 0;
    }
  }
}

.loadUIOverrides();
