/*******************************
            Theme
*******************************/

/*
  The type is the type of the component as defined in semantic-ui - element, collection, view, module
  For simple component, 'element' is the correct type
*/
@type: "collection";

/*
  This is the name of the component - though not required, keep it the same as the filename
*/
@element: "dl_table";

/*******************************

    DO NOT FORGET !!!

Edit *theme.config* in your site 
and add (replace 'sample' with the
name of the component from above)

@sample : 'default';

*******************************/

@import (multiple) "../../theme.config";

/*
  This is the styling of your component. The class should start with .ui.<component-name>

  Note: use variables here, not the exact values. Placing the values to another file (sample.variables)
  will allow users to override these values.
 */
dl.ui.table {
  font-size: 1rem;
  display: flex;
  flex-flow: row wrap;

  & > dt {
    flex-basis: 15%;
    padding: 0px 4px;
    margin-bottom: 1em;
    font-weight: 600;
    min-width: 10em;
  }
  & > dd {
    flex-basis: 70%;
    flex-grow: 1;
    margin-bottom: 1em;
    break-after: always;
  }

  @media only screen and (max-width: @largestMobileScreen) {
    & > dt {
      flex-basis: 100%;
    }
    & > dd {
      flex-basis: 100%;
    }
  }
}

/*
  This will load the overrides, enabling the user of your component to override your css if required
 */
.loadUIOverrides();
