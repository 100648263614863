/*******************************
            Theme
*******************************/

/*
  The type is the type of the component as defined in semantic-ui - element, collection, view, module
  For simple component, 'element' is the correct type
*/
@type: "collection";

/*
  This is the name of the component - though not required, keep it the same as the filename
*/
@element: "frontpage_form";

/*******************************

    DO NOT FORGET !!!

Edit *theme.config* in your site 
and add (replace 'sample' with the
name of the component from above)

@sample : 'default';

*******************************/

@import (multiple) "../../theme.config";

/*
  This is the styling of your component. The class should start with .ui.<component-name>

  Note: use variables here, not the exact values. Placing the values to another file (sample.variables)
  will allow users to override these values.
 */

.ui.frontpage_form {
  margin: 0 auto;
  @media only screen and (min-width: @tabletBreakpoint) {
    max-width: 40%;
  }
  @media only screen and (min-width: @largestMobileScreen) and (max-width: @tabletBreakpoint) {
    max-width: 50%;
  }
  @media only screen and (max-width: @tabletBreakpoint) {
    width: 50vw;
  }
}

.loadUIOverrides();
